/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { SmartLink } from '@/utils/smartLink';
import { clickTrack } from '@/utils/analytics/clickTrack';
import styles from './CheckoutSmartLink.module.scss';
import { sessionStorage } from '@/utils/sessionStorage';

type CheckoutSmartLinkProps = {
  smartLinkMessage?: string;
};

export const CheckoutSmartLink: React.FC<CheckoutSmartLinkProps> = ({ smartLinkMessage }) => {
  const handleClick = () => {
    sessionStorage.setItem('fromCheckout', 'checkout');
    clickTrack({
      eventType: 'editInYourCartCheckout',
    });
  };

  return (
    <SmartLink
      onClick={handleClick}
      to="/cart"
      className={styles.reviewInCart}
      aria-label="Review in Cart"
      data-testid="checkout-your-cart-review-in-cart"
      rel="nofollow"
    >
      {smartLinkMessage}
    </SmartLink>
  );
};
