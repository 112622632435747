/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getDeviceType } from '@/utils/getDeviceType'; // eslint-disable-line no-restricted-imports
import type { CertonaOptions } from '../interface';
import { getCertonaOptions } from '../utils/getCertonaOptions';

/**
 * This helper method is for calling the analytic part of the CERTONA service
 * for the recommendations part use the custom hook
 * @param {CertonaOptions} certonaOptions this are the options for the certona call
 */
export async function callToCertona(certonaOptions: CertonaOptions) {
  const configuredCertonaOptions = getCertonaOptions(certonaOptions);

  if (typeof window !== 'undefined') {
    window.certona = { device: getDeviceType(), ...configuredCertonaOptions };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await window.callCertona?.();
  }
}
