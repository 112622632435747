/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { getAxios } from '@/lib/axios';
import { createQuery } from '@/utils/createReactQuery';
import type { AxiosInstance } from 'axios';
import type { QueryClient } from '@tanstack/react-query';
import type { ProductSkuDetails, SkuDetailsResponse } from '../interface';
import type { FulfillmentOptionFromAPI } from '@/types/availability';
import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { useFeatureFlag } from '@/features/globalConfig';

const URLV2 = `${requestBaseURL}/ecomm/b2c/v3/browse/sku/details`;
const URLV3 = `${requestBaseURL}/ecomm/b2c/browse/v3/skus/price-availability`;

type Options = {
  skuIds: Array<string | null> | null;
  // These are not used in the API call, but the result varies depending on them
  // So we need to use them in the key for the cache
  vehicleId: string | undefined;
  storeNumber: string | undefined;
  isNewDealsApiEnabled?: boolean;
};

export function getFulfillmentOptionsByGroup(
  fulfillmentOptionsFromApi?: FulfillmentOptionFromAPI[] | null
): FulfillmentGroup[] {
  if (!fulfillmentOptionsFromApi) {
    return [];
  }

  const generateGroupFulfillmentOption = (
    fulfillmentOption: FulfillmentOptionFromAPI
  ): FulfillmentOption => ({
    alternateOptionsLabel: fulfillmentOption.alternateOptionsLabel,
    available: fulfillmentOption.available,
    availableLabel: fulfillmentOption.availableLabel,
    availableQty: fulfillmentOption.availableQty,
    estimatedDeliveryDate: fulfillmentOption.estimatedDeliveryDate,
    fulfillmentCutOffTimeMessage: fulfillmentOption.fulfillmentCutOffTimeMessage,
    fulfillmentTypeId: fulfillmentOption.fulfillmentTypeId,
    fulfillmentTypeLabel: fulfillmentOption.fulfillmentTypeLabel,
    fullfillmentCutOffDate: fulfillmentOption.fullfillmentCutOffDate,
    groupId: fulfillmentOption.groupTypeId,
    groupLabel: fulfillmentOption.displayLabel,
    selected: fulfillmentOption.selected,
    sisterStoreAvailable: fulfillmentOption.sisterStoreAvailable,
    dealsAvailableQty: fulfillmentOption.dealsAvailableQty,
    numberOfSisterStoreAvailable: fulfillmentOption.numberOfSisterStoreAvailable,
  });

  const fulfillmentOptionsGroupList: Record<number, FulfillmentGroup> = {};

  fulfillmentOptionsFromApi.forEach((fulfillmentOption) => {
    if (fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId]) {
      fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].groupFulfillmentOptions.push(
        generateGroupFulfillmentOption(fulfillmentOption)
      );
      // if the current group is marked as unavailable, and the new option is
      // available, then set it to available since we only need one of them
      // to be true for the group to be selectable
      if (
        fulfillmentOption.available &&
        !fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].available
      ) {
        fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].available =
          fulfillmentOption.available;
      }
      // if the current group is marked as not selected, and the new option is
      // selected, then set the group to seelcted since if any option is selected
      // the group is selected
      if (
        fulfillmentOption.selected &&
        !fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].selected
      ) {
        fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].selected =
          fulfillmentOption.selected;
      }
      // get the max available quantity by selecting the highest qty among
      // all the options
      if (
        fulfillmentOption.availableQty >
        fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].maxAvailableQty
      ) {
        fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId].maxAvailableQty =
          fulfillmentOption.availableQty;
      }
    } else {
      fulfillmentOptionsGroupList[fulfillmentOption.groupTypeId] = {
        groupId: fulfillmentOption.groupTypeId,
        groupLabel: fulfillmentOption.displayLabel,
        groupFulfillmentOptions: [generateGroupFulfillmentOption(fulfillmentOption)],
        selected: fulfillmentOption.selected, //check business rules for which is the defaulted by default.
        available: fulfillmentOption.available,
        maxAvailableQty: fulfillmentOption.availableQty || 0,
      };
    }
  });
  const unSorted = Object.values(fulfillmentOptionsGroupList);
  return unSorted.sort((a, b) => b.groupId - a.groupId);
}

const selector = (data: SkuDetailsResponse): ProductSkuDetails[] => {
  return data.map((skuDetailsResponse) => {
    const defaultFulfillmentGroupId =
      skuDetailsResponse.skuPricingAndAvailability.skuAvailabilityInfo.defaultFulfillmentGroupId ??
      '';
    const fulfillmentOptions = getFulfillmentOptionsByGroup(
      skuDetailsResponse.skuPricingAndAvailability.skuAvailabilityInfo.fulfillmentOptions
    );

    // for fulfillmentV2 we want to map the api response to what the ui screens
    // in which we have two groups instead of individual fulfillment types.
    // we do the mapping and bring the options to the top level of the
    // sku details object.
    return {
      defaultFulfillmentGroupId,
      fulfillmentOptions,
      ...skuDetailsResponse,
    };
  });
};

const getProductSkuDetails = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { skuIds, isNewDealsApiEnabled = false } = options;

  if (!skuIds) {
    throw new Error('skuIds is required');
  }
  const URL = isNewDealsApiEnabled ? URLV3 : URLV2;

  const response = await getAxios(axiosInstance).get<SkuDetailsResponse>(
    `${URL}/${skuIds.join(',')}`
  );

  return selector(response.data);
};

const {
  useData: useProdSkuDetails,
  prefetch: prefetchProdSkuDetails,
  query: getProdSkuDetailsQuery,
} = createQuery<ProductSkuDetails[], Options>('productSkuDetails', getProductSkuDetails);

export const useProductSkuDetails = ({
  skuIds,
  enabled = Boolean(skuIds?.length),
}: {
  skuIds: string[] | null;
  enabled?: boolean;
}) => {
  const headerResult = useHeaderData();
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const storeNumber = headerResult.data?.storeNumber;
  const vehicleId = getPreferredVehicle(headerResult.data)?.catalogVehicleId;

  return useProdSkuDetails({
    enabled: headerResult.isSuccess && enabled && !!skuIds,
    skuIds: skuIds?.length ? skuIds : null,
    storeNumber: storeNumber ?? '',
    // All falsy types should fall back to this value
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    vehicleId: vehicleId || '0',
    isNewDealsApiEnabled: isNewDealsApiEnabled,
    cacheTime: 0,
  });
};

export const prefetchProductSkuDetails = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  options: Options
) => {
  return prefetchProdSkuDetails(queryClient, options, axiosInstance);
};

export const getProductSkuDetailsKey = ({
  skuIds,
  storeNumber,
  vehicleId,
  isNewDealsApiEnabled = false,
}: Options) =>
  getProdSkuDetailsQuery.getFullKey({
    skuIds: skuIds?.length ? skuIds : null,
    storeNumber: storeNumber ?? '',
    // All falsy types should fall back to this value
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    vehicleId: vehicleId || '0',
    isNewDealsApiEnabled: isNewDealsApiEnabled,
  });

export const getProductSkuDetailsFromCache = (queryClient: QueryClient, options: Options) => {
  return queryClient.getQueryData<ProductSkuDetails[]>(getProductSkuDetailsKey(options));
};
