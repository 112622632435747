/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { clickTrack } from '@/utils/analytics/clickTrack';
import type { RemoveCartAnalyticsData } from '@/types/analytics';

export const trackCartRemoval = (removeCartAnalyticsData: RemoveCartAnalyticsData) => {
  const data: any = {
    eventType: 'removeFromCart',
    productIdList: [removeCartAnalyticsData.prodSku],
    productFulfillmentTypeList:
      Number(removeCartAnalyticsData.productFulfillmentType) === FULFILLMENT_METHODS.STOREORDER
        ? [FULFILLMENT_METHODS.STOREORDER]
        : [FULFILLMENT_METHODS.ONLINEORDER],
    originalPartTypeIdList: [removeCartAnalyticsData.originalPartType],
    productRepositoryIdList: [removeCartAnalyticsData.productRepositoryId],
    productBrandList: [removeCartAnalyticsData.brand],
    productPriceList: [removeCartAnalyticsData.price],
    productRequestedQuantityList: [removeCartAnalyticsData.quantity],
  };

  if (removeCartAnalyticsData.productAvailableQuantity) {
    data.productAvailableQuantityList = [`${removeCartAnalyticsData.productAvailableQuantity}`];
  }

  clickTrack(data);
};
